.index {

  .container {
    background-color: $black;
  }

  #site-header {
    background-color: $white;
  }

/*
  #site-header {

    @include breakpoint(medium) {
      position: absolute;
      top: 0px;
      z-index: 1001;
    }
    #main-nav {
      border-bottom: none;
      .top-bar-title {
        margin-right: 5px;
        border-bottom: 1px solid $light-gray;
        img {
          @include breakpoint(large) {
          }
        }
      }
      .social-icons {
        margin-top: 6px;
        margin-right: 15px;
      }

    }
  }
*/

}

.home-featured {
  position: relative;

  .home-featured-copy {
    @include absolute-center;
    padding: 20px 10px;
    text-align: center;
    background: rgba($black, .6);

    @include breakpoint(medium) {
      padding: 40px 30px;
      margin-top: 30px;
    }

    h2 {
      display: inline-block;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: .1em;
      margin: 0px;
      line-height: 1em;
    }

    a {
      color: $white;
      transition: color .5s;
      &:hover {
        color: $gold;
      }
    }
  }
}

.home-artists {
  background: $white;

  .slide {
    background-color: $black;
    a {
      display: block;
      text-align: center;
    }
    img {
      margin: 0 auto;
    }
  }

  .home-artist-link {
    display: block;
    position: relative;

  }

  .section-header {
    padding: 30px 15px 30px 15px;
    text-align: center;
    background: $black;
    color: $white;
    a {
      color: $white;
      transition: color .5s;
      &:hover {
        color: $gold;
      }
    }
      &:before {
      content: "\f105 \f105";
      font-family: FontAwesome;
      font-weight: normal;
      display: inline-block;
      font-size: .75em;
      opacity: .25;
      position: relative;
      top: -.08em;
      margin-right: 8px;
    }

    &:after {
      content: "\f104 \f104";
      font-family: FontAwesome;
      font-weight: normal;
      display: inline-block;
      font-size: .75em;
      opacity: .25;
      position: relative;
      top: -.08em;
      margin-left: 8px;
    }
  }

  .artist-name {
    background: rgba($black, .2);
    color: $white;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    transition: background .5s;

    &:hover {
      background: rgba($black, 0);
    }

    h2 {
      font-weight: 700;
      line-height: 1em;
      letter-spacing: .1em;
      width: 100%;
      text-align: center;
      position: absolute;
      bottom: 20px;
      text-transform: uppercase;
    }
  }
}