.container {
  min-width: 320px;
  background: $white;
  max-width: 1060px;
  margin: 0px auto;
  position: relative;
  box-shadow: 0px 0px 10px rgba($black, .1);

  @include breakpoint(medium) {
    min-height: 100vh;
    padding-bottom: 250px;
  }

  #site-content {
    min-height: 300px;
  }
}

.vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.button {
  text-transform: uppercase;
  letter-spacing: .1em;
}

#site-header {
  width: 100%;
  text-transform: uppercase;
  font-family: $font-futura;

  #mobile-nav {
    background: $offwhite;
    text-align: center;

    .dropdown {
      margin-top: 5px;
      font-weight: 600;
      letter-spacing: .1em;
      border-bottom: 1px solid $light-gray;
    }

    .title-bar {
      border-bottom: 1px solid $light-gray;
      padding: 4px 8px;
      position: relative;

      .menu-icon {
        position: absolute;
        top: 5px;
        left: 10px;
        margin-top: 13px;
      }
      .title-bar-title {
        text-align: center;
        font-weight: 800;
        letter-spacing: .1em;
      }
      img {
        max-height: 40px;
      }
    }
  }

  #main-nav {

    border-bottom: 1px solid $light-gray;
    a {
      font-weight: 600;
      letter-spacing: .1em;
      padding: 1em 1.25em;
      color: $black;
      transition: color .5s;
      &:hover {
        color: $red;
      }
    }
    .top-bar-title {
      //border-right: 1px solid $light-gray;
      margin-right: .15em;

      @include breakpoint(large) {
        margin-right: .75em;
      }

      a {
        padding: 0px;
      }
      img {
        max-height: 65px;
        @include breakpoint(large) {
          max-height: 65px;
        }
      }
    }
    .top-bar-left {
      margin-top: 10px;

      @include breakpoint(large) {
        margin-top: 10px;
      }
    }

    .social-icons {
      margin-right: 15px;
      a {
        padding: 15px 10px;
        font-size: rem-calc(24);
      }
    }

  }
}

#main-content {
  padding: 15px 0px;
  background: $white;

  @include breakpoint(medium) {
    padding: 15px;
  }

  &.artist {
    padding-bottom: 50px;

    @include breakpoint(small only) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.page-title {
  text-transform: uppercase;
  text-align: center;
  letter-spacing: .1em;
  font-weight: 600;
  margin: 10px 0px 20px 0px;

  &:before {
    content: "\f105 \f105";
    font-family: FontAwesome;
    font-weight: normal;
    display: inline-block;
    font-size: .75em;
    opacity: .25;
    position: relative;
    top: -.08em;
    margin-right: 8px;
  }

  &:after {
    content: "\f104 \f104";
    font-family: FontAwesome;
    font-weight: normal;
    display: inline-block;
    font-size: .75em;
    opacity: .25;
    position: relative;
    top: -.08em;
    margin-left: 8px;
  }

  .fa {

    font-size: .75em;
    opacity: .25;
  }
}

.section-header {
  margin: 0px;
  padding: 15px;
  line-height: 1em;
  font-weight: 800;
  font-size: rem-calc(28);
  text-transform: uppercase;
  letter-spacing: .1em;
  background: $white;
  color: $black;
}

.news {
  @include breakpoint(small only) {
    .column {
      padding: 0px;
    }
    .entry-title,
    .entry-body {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .entry {

    padding: 0px 0px 50px 0px;

    header {
      border-top: 1px solid $light-gray;
      border-bottom: 1px solid $light-gray;
      text-align: center;
      margin: 10px auto 20px auto;
    }

    .entry-date {
      color: $medium-gray;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: .1em;
      margin: 0px;
    }

    .entry-title {
      margin: 0px;
      font-weight: 700;
      text-transform: uppercase;
    }

    .entry-image {
      border: 1px solid $offwhite;
      margin: 0px;
      margin-bottom: 1em;
      &.has-caption {
        margin-bottom: 1.5em;
      }
    }

    .image-caption {
      padding: 5px 10px;
      font-style: italic;
    }

    .image-credit {
      text-align: right;
      color: rgba($white, .8);
      padding: 5px 10px;
      height: 30px;
      font-size: 14px;
      margin-top: -30px;

    }

  }

  .quote {
    blockquote {
      font-family: $font-serif;
      font-style: italic;
      font-weight: 400;
      font-size: rem-calc(42);
      color: $black;
      text-align: center;
      line-height: 1.25em;
      padding: 20px 40px;

      a {
        color: $black;
        transition: color .5s;
        &:hover {
          color: $red;
        }
      }

      q {

      }

      cite {
        font-style: normal;
        font-family: $body-font-family;
        text-transform: uppercase;
        letter-spacing: .1em;
      }
    }
  }

  .embed {
    text-align: center;
    .twitter-tweet  {
      margin: 10px auto;
    }
  }

  .news-nav {
    border-top: 1px solid $light-gray;
    padding: 20px 0px;
    text-align: center;
  }
}

.artists {
  .column {
    @include breakpoint(small only) {
      padding: 0px;
    }
  }
  .artist-name {
    margin: 5px 0px 30px 0px;
    h3 {
      font-weight: 600;
    }
  }

}

.artist-gallery {
  background-color: rgba($black, .1);
  .slide {
    img {
      margin: 0px auto;
    }

    .image-credit {
      text-align: right;
      color: rgba($white, .8);
      padding: 5px 10px;
      height: 30px;
      font-size: 14px;
      margin-top: -30px;

    }
  }
}

.artist-link {
  display: block;
  margin-bottom: 20px;
  .artist-image {
    background-color: #ccc;
    border: 1px solid $offwhite;
  }
  img {
    transition: opacity .5s;
  }
  img:hover {
    opacity: .8;
  }
  .artist-no-image {
    opacity: .25;
    &:hover {
      opacity: .5;
    }
  }
  h3 {
    text-align: center;
    font-size: rem-calc(21);
    color: $black;
  }
}

.artist {
  .artist-carousel {
    margin-bottom: 0px;
    .slick-dots {
      bottom: 10px;
    }
  }
  .artist-title {
    text-transform: uppercase;
    text-align: center;
    letter-spacing: .1em;
    font-weight: 600;
    margin: 0px 0px 30px 0px;
    line-height: 1.1em;

    @include breakpoint(medium) {
      margin-top: 10px;
    }
  }
  .entry-body {
    margin-bottom: 20px;
  }
  .links {
    font-size: rem-calc(24);
    line-height: 24px;
    margin-bottom: 15px;

    .label-link {
      position: relative;
      font-weight: bold;
      display: inline-block;
      padding-right: 20px;
      &:after {
        content: "";
        display: block;
        position: absolute;
        right: 8px;
        top: 4px;
        width: 1px;
        height: 32px;
        border-right: 1px solid $light-gray;
      }
      .fa {
        top: 0px;
      }
    }

    a {
      padding-right: 3px;
    }
    .fa {
      position: relative;
      top: 3px;
    }
    .fa-spotify {
      top: 4px;
      font-size: rem-calc(28);
    }
  }

  .playlist {
    margin-bottom: 20px;
    iframe {
      min-width: 100%;
    }
  }
}

#site-footer {
  background-color: $black;
  color: $white;

  @include breakpoint(medium) {
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
  }

  a {
    color: $gold;
    transition: color .5s;
    &:hover {
      color: $white;
    }
  }

  #footer-links {
    padding: 40px 15px 15px 15px;
  }

  #footer-social {
    border-top: 1px solid #222;
    a {
      font-size: rem-calc(24);
    }
  }

  #footer-copyright {
    border-top: 1px solid #222;
  }

  .footer-logo {
    max-width: 80px;
    margin: 0px 20px 20px 0px;
    border: 3px solid $gold;

    @include breakpoint(medium) {
      max-width: 100px;
      margin-right: 20px;
    }
  }

  .footer-tagline {
    font-size: rem-calc(18);
    font-weight: 700;
  }

  .footer-links {
    list-style: none;
    text-transform: uppercase;
    letter-spacing: .1em;
    margin: 0px;
    font-size: rem-calc(14);
  }

  .copyright {
    font-size: rem-calc(14);
    color: $dark-gray;
    text-align: center;
    margin-top: 20px;
  }
}