.coming-soon {
  background: $white;
  min-width: 320px;
  min-height: 420px;
  #site-content {
    @include absolute-center;
    text-align: center;
    //border: 1px solid $dark-gray;
    padding: 10px;
    img {
      max-width: 200px;
    }
    h3 {
      margin: 5px 0px;
      font-size: rem-calc(18);
      text-transform: uppercase;
    }
  }
}